.toolbar_container {
    width: 100%;
    /*position: fixed;*/
    /*display: inline-flexbox;*/
    /*background-color: #1c2126;*/
    background-color: #353c4a;
    top: 0;
    left: 0;
    box-shadow: 1px 0px 7px rgba(0,0,0,0.5);
    flex:1;
  }

.toolbar {
    width: 100%;
    position: fixed;
    /*background-color: #1c2126;*/
    background-color: #353c4a;
    top: 90px;
    left: 0;
    box-shadow: 1px 0px 7px rgba(0,0,0,0.5);
}

.toolbar-top {
    background-color: #282c34;
    width: 100%;
    top: 0;
    left: 0;
    height: 90px;
    display: inline-flex;
    align-items: center;
    padding: 0;
    margin: 0;
}

.toolbartop_box{
    display: inline-flex;
    align-items: center;
    height: 90px;
    padding: 0 2rem
}

/*.toolbar-top {
    background-color: #282c34;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 90px;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
}

.toolbartop_box{
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 2rem
}
*/

.toolbartop_box_contact{
    background-color: #ed6b21;
    display: inline-flex;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    flex-direction: column;
    justify-content: right;
}

.top-toolbar-logoContainer{
    display: inline-flex;
    align-items: center;
    height: 100%;
    padding: 0 10px;
}

.compcontact-bold {
    vertical-align: middle;
    font-family: Open Sans,sans-serif;
    font-size: 20px;
    font-weight: 550;
    text-transform: inherit;
    /*color: #282c34;*/
    color: #ffffff;
    margin-top: 10px;
    text-align: left;
    line-height: 15px;
    list-style-type:none;
  }

  .compcontact-narrow {
    vertical-align: middle;
    font-family: Open Sans,sans-serif;
    font-size: 15px;
    font-weight: 400;
    text-transform: inherit;
    color: #282c34;
    text-align: right;
    line-height: 15px;
    list-style-type:none;
    margin-top: 0px;
  }

.spiel {
    vertical-align: middle;
    font-family: Open Sans,sans-serif;
    font-size: 20px;
    font-weight: 500;
    text-transform: inherit;
    color: whitesmoke;
    text-align: center;
    line-height: 20px;
    list-style-type:none;
    margin-top: 10px;
  }

  .boltseparator {
    height: 90px;
    width: auto;
    margin:0 auto !important;
    padding: 0px;
  }

.spacer {
    flex: 1;
}

.toolbar_navigation{
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 1rem
}

.toolbar_logo {
    margin-left: 1rem;
    padding: 0 1rem;

}

.toolbar_logo_image {
    width: 90%;
    height: 90%;
    /*animation: rotation 5s infinite linear;*/
}

@keyframes rotation {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(359deg);
    }
  }

.toolbar_logo a {
    color: #ed6b21;
    font-weight: 450;
    text-decoration: none;
    font-size: 1.2rem;
}

.toolbar_logo a:hover {
    color: #ffffff;
    font-weight: 470;
    width: 40px
}

.toolbar_navigation_items ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.toolbar_navigation_items li {
    padding: 0 2rem;  
    }

.box-e {
    position: relative;
    display: block;
    width: auto;
    text-align: center;
    cursor: pointer;
    height:90px;
    line-height: 90px;
}

.custom-underline,
.custom-underline:hover,
.custom-underline:focus,
.custom-underline:active {
  color: #ed6b21;
  text-decoration: none;
}

.custom-underline::after {
  content: "";
  position: absolute;
}

.box-e .custom-underline::after {
    top: 65%;
    height: 2px;
    width: 0%;
    left: 5%;
    background-color: #ed6b21;
      transition: 0.2s ease all .2s;
  }
  
  .box-e:hover .custom-underline::after {
    width: 90%;
      transition: 0.2s ease all;
  }

.toolbar_navigation_items li.with-submenu {
    padding: 0 2rem;
    color: white;
    text-decoration: none;
}

.toolbar_navigation_items a {
    color: white;
    text-decoration: none;
}

.toolbar_navigation_items a:hover,
.toolbar_navigation_items a:active {
    color: #fa923f;
    font-size: 105%;
}

.toolbar_navigation_items.menu {
    color: white;
    text-decoration: none;
}

.toolbar_navigation_items.menu:hover,
.toolbar_navigation_items.menu:active {
    color: #fa923f;
}

.toggle_button {
    height: 24px;
    width: 30px;
    display: flex;
    justify-content: space-around;
    background: transparent;
    border: none;
    cursor: pointer;
    flex-direction: column;
    box-sizing: border-box;
}

.toggle_button:hover
{
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
}

.toggle_button:focus {
    outline: none;
}

.toggle_button_line {
    width: 30px;
    height: 2px;
    background: white;
}

.toggle_button_line:hover
{
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
}

.buyerschoice_href{
    display:block;
}

.buyreschoice_badge{
    background:url(https://d3cl59gmk6jgfj.cloudfront.net/images/widgetm4uaward20.png) no-repeat 0 0;
    height:106px;
    width:227px;
}

.buyerschoice_compnamecontainer{
    position:relative;
    top: 35px;
    left: 135px;
}

.buyerschoice_span{
    color:#fff;
    position:relative;
    width:82px;
    font-size:9px;
    height:32px;
    display:table-cell;
    vertical-align:middle;
    font-family:Helvetica;
}