.toolbar {
  width: 100%;
  /*position: fixed;*/
  background-color: #1c2126;
  /*position: -webkit-sticky; 
  position: sticky;*/
  background-color: #353c4a;
  top: 90px;
  top: 0;
  left: 0;
  box-shadow: 1px 0px 7px rgba(0,0,0,0.5);
}

.toolbar .sticky-inner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.filler {
  flex: 1;
}

.spacer-20 {
  width: 20px;
}

.apc-sticky{
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
  width: 100%;
  height: 70px !important;
  z-index: 100;
}

.apc-custom{
  width: 100%;
  height: 70px !important;
  background-color: #353c4a !important;
  display: flex;
  align-items: center;  
}

.apc-custom a {
    color: white;
    text-decoration: none;
  }

  .apc-custom a:hover,
  .apc-custom a:active {
    color: #fa923f;
    /* font-size: 105%; */
}

.nav-item {
    position: relative;
    display: block;
    width: auto;
    text-align: center;
    cursor: pointer;
    height:auto;   
}

.dropdown {
    position: relative;
    display: block;
    width: auto;
    text-align: center;
    cursor: pointer;  
}

.custom-underline,
.custom-underline:hover,
.custom-underline:focus,
.custom-underline:active {
  color: #ed6b21;
  /*color: #000000;*/
  text-decoration: none;
}

.custom-underline::after {
  content: "";
  position:absolute;
}

.nav-item .custom-underline::after {
    top: 95%;
    height: 2px;
    width: 0%;
    left: 5%;
    background-color: #ed6b21;
      transition: 0.2s ease all .2s;
  }
  
  .nav-item:hover .custom-underline::after {
    width: 90%;
      transition: 0.2s ease all;
  }

  .primary.dropdown-toggle{
      background-color: transparent !important;
      border: 0;
  }

  .dropdown-menu.show{
    /*background-color:#394868;*/
    background-color:#0062cc;
    box-shadow: 1px 0px 7px rgba(0,0,0,0.5);
  }
/*244381*/
  .dropdown-toggle.btn.btn-primary {
      background-color: transparent !important;
      box-shadow: none !important;
      border-style: solid;
      border-color: transparent;
      border-radius: 0%;
      text-transform: none !important;
  }

  .dropdown-toggle.btn.btn-primary:hover {
    background-color: transparent !important;
    color: #fa923f;
    /* font-size: 105%; */
    border-bottom-color:  #fa923f;
    border-bottom-width: 2px;
}

/*.navbar .apc-custom .nav-item .dropdown-menu .dropdown-item:hover { 
    background-color: #353c4a;
}*/
.dropdown-item{
  color: antiquewhite !important;
 font-size: 1rem !important; 
}

.dropdown-item:hover { 
    background-color: #353c4a !important;
    color: #fa923f !important;
}


.toggle_button {
  height: 24px;
  width: 30px;
  display: flex;
  justify-content: space-around;
  background: transparent !important;
  border: none;
  cursor: pointer;
  flex-direction: column;
  box-sizing: border-box;
}

.toggle_button:hover
{
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
}

.toggle_button:focus {
  outline: none;
}

.toggle_button_line {
  width: 30px;
  height: 2px;
  background: white;
}

.toggle_button_line:hover
{
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
}




