@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&family=Quicksand&display=swap');
.contacts-wrapper-grid{
    width: 100rem;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: 36rem auto;
    font-family: 'Open Sans Condensed', sans-serif;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
}

.contactus-container{
    padding: 1rem;
    width: auto;
    margin-top: 1rem;
    margin-left: 1rem;
    border-style: solid;
    border-width: 0.7px;
    border-color: rgb(172, 169, 169);
    border-radius: 5px;
    background-color: whitesmoke;

}

.contact-cards-wrapper{
    display: grid;
    grid-template-columns: auto auto;
}

.contact-card-container{
    padding: 1rem;
    margin-left: 1.2rem;
}

.contacts-name-box{
    background-color: rgb(243, 116, 65);
}

.contacts-header-title{
    text-align: center;
    font-size: 2.5rem;
    font-weight: 500;
    font-family: 'Open Sans Condensed', sans-serif;
}

.contacts-name{
    font-size: 1.8rem;
    font-weight: 500;
    color: whitesmoke;
}

.contacts-position{
    font-size: 1.5rem;
    font-weight: 500;
    color: rgb(113, 123, 156);
}

.contacts-details{
    font-size: 1.2rem;
    color: #000000;
}