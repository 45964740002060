.side_drawer {
    height: 100%;
    background-color: #353c4a;
    box-shadow: 1px 0px 7px rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 400px;
    z-index: 1000;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
}

.side_drawer.open {
    top: 0;
    left: 0;
    transform: translateX(0);
}

.side_drawer ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.side_drawer li {
    margin: 0.7rem 0;
}

.side_drawer a {
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 400;
}

.side_drawer a:hover,
.side_drawer a:active {
    color: #fa923f;
}

.drawer_logo_image{
    width: 116px;
    height: 100px;
    /* animation: rotation 8s infinite linear; */
}

.drawer_logo_image:hover{
    animation: rotation 8s infinite linear;
}


.drawer_logo_container {
    width: 100%;
    max-width: 400px;
    text-align: center;
}