@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&family=Quicksand&display=swap');
.ps-carousel-container{
    position: relative;
    height: 400px;
    width: 100%;
    overflow: hidden;
    text-align: center; 
}

.ps-content-wrapper{
    width: 40rem;
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.ps-article-content-wrapper{
  width: 70rem;
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.logo-wrapper{
    /* border-style: solid;
    border-width: 2px;
    border-color: black; */
    width: 470px;
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.text-content-ps{
    text-align: center;
    margin-top: 4rem;
    vertical-align: middle;
    vertical-align: -webkit-baseline-middle;
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 2.7em;
}

.ps-bg-img-container{
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    height: 100%; 
    width: 100%;
    color: white;
    text-align: center;
    display: table;
  }

.ps-bg-text-container{
    
    height: 100%;
    width: 100%;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10rem;
    background-color: black;
    overflow: hidden;
}

  .ps-bg-01 {
    background-image: url("https://strapi.australianpower.net/carousel/power-station.jpg");
  }

  .ps-mask {
  
    cursor: pointer;
    /* display: inline-block; */
    overflow: hidden;
    
    /* Background to mask */
    background-position: 0% 50%; 
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -o-background-clip: text;
    background-clip: text;
    
    /* Text size and style */
    font-size: 95px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -10px;
    padding: 0 3px;
    text-shadow:0px 0px 0px #fff;
    margin: 0;
  }

  .ps-photo-origin{
      font-size: 14px;
      font-style: italic;
      text-align: right;
      line-height: 150px;
      margin-right: 2rem;
  }

  .ps-link{
    color: rgb(241, 140, 24)
  }

  .ps-link:hover{
    color: rgb(248, 224, 87);
    text-decoration: none;
  }

  .ps-content-grid{
    margin-left: auto;
    margin-right: auto;
    width: 80rem;
    display: grid;
    grid-template-columns: auto auto;
  }

  .ps-content-grid-vertical{
    margin-left: auto;
    margin-right: auto;
    width: 80rem;
    display: grid;
    grid-template-columns: auto;
  }

  .ps-photo-grid{
    width: 40rem;
    display: grid;
    grid-template-columns: auto auto auto;
  }

  .ps-photo{
    width: 13rem;
    margin: 3px;
    border-style: solid;
    border-color: grey;
    border-width: 1px;
  }

  .ps-header-photo{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-style: solid;
    border-color: grey;
    border-width: 1px;
  }

  .ps-photo-grid-2columns{
    width: 40rem;
    display: grid;
    grid-template-columns: 35% 65%;
  }

  .ps-photo-column-01{
    width: 13rem;
    margin: 3px;
    border-style: solid;
    border-color: grey;
    border-width: 1px;
  }

  .ps-photo-column-02{
    width: 26rem;
    margin-right: 3px;
    margin-top: 3px;
    margin-left: 0;
    border-style: solid;
    border-color: grey;
    border-width: 1px;
  }

  .ps-list-01{
    padding-left: 2rem;
  }

  .sigbeef-carousel-container-div{
    width: 80rem;
    height: 50rem;
}