@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&family=Quicksand&display=swap');
.apc-contact-drawer-container {
    
    overflow: hidden;
    width: 60rem;
}

.apc-contact-drawer-overlay {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    background-color:transparent;
    overflow: hidden;
    width: 100%;
    height:0;
    transition: .5s ease;
  }

  .apc-contact-drawer-overlay-bg {
    background-color:transparent;
    overflow: hidden;
    bottom: 0;
  } 

  .apc-contact-us-button{
    background-color: transparent;
    display: grid;
    grid-template-columns: 2rem auto;
    cursor: pointer;
  }

  .apc-contact-us-text{
    font-family: 'Open Sans Condensed', sans-serif;
      font-size: 2rem;
      font-weight: 500;
      transition: font-weight 1s ease;
  }

  .apc-contact-us-arrow {
    font-size: 3rem;
    color: hsl(24, 100%, 54%);
    background-color: transparent;
    transition: transform 1s ease;
  }

  .apc-contact-us-button:hover .apc-contact-us-arrow{
    transform: rotate(90deg);
  }

  .apc-contact-us-button:hover .apc-contact-us-text{
    font-weight: 700;
  }