.side_drawer {
    height: 100%;
    background-color: #353c4a;
    box-shadow: 1px 0px 7px rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 300px;
    z-index: 100;
    text-align: left;
}

.apc-link {
    color: black !important;
    font-size: 1.1rem;
    transform: scale(1);
    transition: all 2s ease !important;
}

.apc-link-container{
    width: 100%;
    background-color: transparent;
    transform: scale(1);
    transition: all 0.7s ease !important;
}

.apc-link-container:hover{
    transform: scale(1.2);
}

.apc-link:hover {
    text-decoration: none;
}

.apc-link:visited{
    font-weight: bold;
    color: black !important;
}