@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&family=Quicksand&display=swap');
.product-detail {
    width: 100%;
    
    /* max-width: 600px; 
    margin: 1rem;
    */
}

.product-detail .carousel .slide {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
}


.product-carousel-image{
    width: 42rem;
    height: 30rem;
}