.apc-checkbox-container{
    padding: 5px !important;
    cursor: pointer;
}

.apc-checkbox-container:hover{
    font-weight: bold;
    cursor: pointer;
}

.apc-textbox-container{
    padding: 4px;
}

.apc-textbox{
    border-color: rgb(129, 129, 129);
}

.apc-categories-container{
   margin-top: .8rem;
   width:14rem;
   margin-left: 1.2rem; 
}

.apc-categories-header{
    font-size: 1.1rem;

}