@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&family=Quicksand&family=Squada+One&display=swap');
section {
    display: block;
}

.section.is-medium {
    padding: 1.5rem 1.5rem;
}

.wrapper-content{
    width: 100%;
    background-color: ivory;
    min-height: 30rem;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
    display: block;
}

.wrapper-category{
    display: grid;
    grid-template-columns: 9rem auto;
    width: 100%;
    background-color: #1c2126;
    min-height: 30rem;
    /*
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
    display: block;
    */
}

.wrapper-category-small{
    display: grid;
    grid-template-columns: 9rem auto;
    width: 100%;
    background-color: #1c2126;
    min-height: 19rem;
 
}

.homepage-content-row{
    background-color:#3f485a;
    display: grid;
    grid-template-columns: auto 120rem auto;
    grid-gap: 5px;
}

.homepage-content-row-small{
    background-color:#3f485a;
    display: grid;
    grid-template-columns: auto 1300px auto;
    grid-gap: 5px;
}

.categories-content-row{
    background-color:#1c2126;
    display: grid;
    grid-template-columns: auto 120rem auto;
    grid-gap: 5px;
}

.content-grid {
    display: grid;
    grid-template-columns: auto 30rem;
  }

.container-text{
    vertical-align: middle;
    display: inline-block;
    margin-left: 4rem;
    margin-right: 2rem;
}

.container-profilepic{
    display: inline-block;
    margin-right: 2rem;
}

.text-content{
    margin-top: 4rem;
    vertical-align: middle;
    vertical-align: -webkit-baseline-middle;
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 2.7em;
}

.text-content-sub{
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 1.5em;
}

.text-content-name{
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 1.7em;
}

.profilepic{
    width: 25rem;
    height: 25rem;
    border-radius: 50%;
    border: 15px solid #fa923f
}

.container-category{
    /* border-style: solid;
    border-width: 1px;
    border-color: tomato; */
    
    margin-top: 1rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width:100%;
}

.category-label{
 
 background-color: coral;
}

.category-label-small{
 
    background-color: coral;
    width: 115px;
    height: 350px;
   }

.container-category-cards{
    /*border-style: solid;
    border-width: 1px;
    border-color: rgb(51, 113, 207);*/
    padding-top: .5rem;
    padding-bottom: .5rem;
    display: grid;
    grid-template-columns: auto;
}

.apc-contact-container{
    margin-top: 4rem;
}

.img_category_label_small{
    position: relative;
    top: -35px;
}

.img_category_label{
    position: relative;
    top: 0px;
}