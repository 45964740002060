@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&family=Quicksand&display=swap');
.section {
    display: block;
}

.is-medium {
    padding: 4rem 1.5rem;
}

.aboutus-content-wrapper{
    width: 60rem;
    margin-right: auto;
    margin-left:auto;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
}

.aboutus-header-text{
    color: rgb(126, 125, 125);
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 2.7em;
    font-weight: 500;
    text-transform: uppercase;
    text-align: left;
}

.aboutus-content-text{
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 1.5em;
    margin-left: 4rem;
    margin-right: 2rem;
}

.emphasize{
    font-weight: bold;
    margin-left: 1rem;
}

.footer{
    font-style: italic;
    margin-left: 1rem;
}

.aboutus-specialise-text{
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 1.5em;
    margin-left: 1rem;
    margin-right: 1rem;
    color: rgb(84, 85, 85);
}

.specialise-card{
    margin-right: 4rem !important;
}

.aboutus-2column{
    display: grid;
    grid-template-columns: 25rem auto;
}

.apc-logo-spec{
    padding-left: 5px;
    margin-left: 1rem;
    width: 29rem;
    max-height: 40rem;
    height: auto;
}

.aboutus-img-container{
    margin-left: 4rem;

}

.aboutus-img{
    width: 500px;
    height: 296px;
}

.aboutus-img-wide{
    width: 700px;
    height: auto;
}

.aboutus-img-wider{
    width: 900px;
    height: auto;
}

.history-pics-01{
    margin-top: 3.1rem;
    transform: rotate(-15deg);
    width: 500px;
    height: 296px;
    box-shadow: 5px 3px 10px grey;
}

.history-pics-02{
    margin-top: 2rem;
    transform: rotate(5deg);
    width: 500px;
    height: 296px;
    box-shadow: 5px 3px 10px grey;
}

.history-pics-03{
    margin-top: 2rem;
    width: 700px;
    height: auto;
    box-shadow: 5px 3px 10px grey;
}

.history-pics-04{
    margin-top: 1rem;
    transform: rotate(7deg);
    width: 700px;
    height: auto;
    box-shadow: 5px 3px 10px grey;
}

.history-pics-05{
    margin-top: 6rem;
    transform: rotate(-10deg);
    width: 500px;
    height: 296px;
    box-shadow: 5px 3px 10px grey;
}

.history-pics-06{
    margin-top: 2rem;
    margin-bottom: 3rem;
    width: 900px;
    height: auto;
    box-shadow: 5px 3px 10px grey;
}

.img-comment{
    text-align: center;
    font-style: italic;
    font-size: 1.1rem;
}



