/* .card .product-list {
    width: 20rem;
} */


.card-body {
    padding: 2rem 0 !important;
    
}

.card-text {
    font-size: 0.9rem;
    padding: 0.4rem 1.9rem;
}

.container-fluid .row{
    padding-top:  0.7rem;
}

.overflow{
    overflow: hidden;
    
}

.card-img-top {
    transform: scale(1);
    transition: all 1s ease !important;
    
}

.card-img-top:hover {
    transform: scale(1.5);
}

.shortdesc-container {
    height: 6rem;
    overflow: hidden;
    margin-bottom: 1rem;
}

.product-title-container {
    height: 5.2rem;
    overflow: hidden;
}

.main-modal {
    min-width: 75%;
}

.row {
    display: flex;
  }
  
  /* Create two equal columns that sits next to each other */
  .column {
    flex: 50%;
    padding: 10px;
    
  }

  .carousel-container {
    border-width: 1px;
    border-color: darkgrey;
    align-items: center;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    /*box-shadow: 5px 5px 10px 1px rgba(0,0,0,0.253);*/
}

.email-price-row {
    display: table;
    width: inherit; /*Optional*/
    table-layout: fixed; /*Optional*/
    /*border-spacing: 10px; Optional*/
    margin-top: 1rem;
}

.email-price-column {
    display: table-cell;
}

.email-price-column-right {
    display: table-cell;
    float: right;
}

.btn-enquire{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 1.1rem;
    font-weight: 400;
    color: #fff !important;
    text-transform: uppercase;
    text-decoration: none;
    background: #ed3330;
    padding: 15px;
    border-radius: 5px;
    border-width: 1px;
    border-color: #fff;
    /* display: inline-block; */
    border: none;
    transition: all 0.4s ease 0s;
}

.btn-enquire:hover{
    background: #434343;
    border-width: 0px;
    letter-spacing: 1px;
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
    -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
    box-shadow: 5px 40px -10px rgba(0,0,0,0.57);
    transition: all 0.4s ease 0s;
}

/* General button style */
.btn-enq {
    border: none;
    border-radius: 3px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.9rem;
    color: inherit;
    background: none;
    cursor: pointer;
    padding: 10px 60px;
    display: inline-block;
    margin: 5px 5px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    outline: none;
    position: relative;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.btn-enq:after {
    content: '';
    position: absolute;
    z-index: -1;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

/* Pseudo elements for icons */
.btn-enq:before {
    font-family: 'FontAwesome';
    /* speak: none; */
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    position: relative;
    -webkit-font-smoothing: antialiased;
}


/* Icon separator */
.btn-sep {
    padding: 25px 60px 25px 120px;
}

.btn-sep:before {
    background: rgba(0,0,0,0.15);
}


.btn-4 {
    background: #34495e;
    color: #fff;
}

.btn-4:hover {
    background: #2c3e50;
}

.btn-4:active {
    background: #2c3e50;
    top: 2px;
}

.btn-4:before {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    line-height: 3;
    font-size: 140%;
    width: 60px;
}

/* Icons */

.icon-cart:before {
    content: "\f07a";
}

.icon-heart:before {
    content: "\f55a";
}

.icon-info:before {
    content: "\f05a";
}

.icon-send:before {
    content: "\f1d8";
}
/*.card-img-top .photo-header{
    height: 250px;
    width: 200px;
}*/

.emailform-container{
    padding: 10px;
}

.portables-container-grid {
    display: grid;
    grid-template-columns: auto auto auto;
    padding-top: 3px;
  }

.container-grid {
    display: grid;
    grid-template-columns: auto auto auto auto;
    padding-top: 3px;
  }

  .container-productlist-pageoptions {
    width: 100%;
    text-align: center;
    padding: 5px;
  }

  .container-paginator{
    display: inline-block;
    padding: 0;
    margin-right: 2rem;
  }
  
  .grid-item {
    /* background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8);
    padding: 20px;
    font-size: 30px; */
    width: 19em;
    height: 37rem;
    padding: 9px;
  }

  .pagination>li>a{
    padding: 0.75rem 1rem;
    min-width: 3.2rem;
    text-align: center;
    box-shadow: none !important;
    border-color: #ced4da !important;
    color: saturate(darken(#ced4da, 30%), 10%);
    font-weight: 900;
    font-size: 1rem;
  } 

  .pagination>li>a:active{
    color: saturate(darken(#ced4da, 50%), 5%) !important;
    background-color: saturate(lighten(#ced4da, 7.5%), 2.5%) !important;
    border-color: #ced4da !important;  
  }

  .pagination>li>a:hover{
    background-color: lighten(desaturate(#ced4da, 50%), 12.5%);
  }

  .pagination>ul{
    margin-top: 0;
    margin-bottom: 0;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }

  .aws-btn {
    font-family: Arial, Helvetica, sans-serif !important;
    }

  .container-productdetails-grid{
      display: grid;
      grid-template-columns: 42rem auto;
      padding-top: 3px;
  }

  .details-container {
    margin-right: .9rem;
    max-height: 90%;
    overflow: auto;
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 1.1rem;
}

.price-container {
    /* background: #f12711;
    background: -webkit-linear-gradient(to right, #f5af19, #f12711);  
    background: linear-gradient(to right, #f5af19, #f12711);  */

    background: #BBD2C5;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #292E49, #536976, #BBD2C5);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #292E49, #536976, #BBD2C5); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */



    color: #fefefe;
    text-align: center;
    font-family: 'Open Sans Condensed', sans-serif;
    /*font-family: Impact, Charcoal, sans-serif;*/
    font-size: 1.9rem;
    font-weight: bolder;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 5.5rem;
    padding-top: 5px;
    /*box-shadow: 5px 5px 10px 1px rgba(0,0,0,0.253);*/
    display: flex;
    flex-direction: column;
    overflow: hidden;
    
}

.productlist-wrapper{
    display: grid;
    grid-template-columns: 18rem auto;
}

.portablelist-wrapper{
    display: grid;
    grid-template-columns: 25rem auto;
}

.productlist-search-container{
    margin-top: 1rem;
    border-style: none;
    border-width: 0;
}

.productdetail-url {
    font-size: 1.3rem;
    color: rgb(37, 125, 240);
}

/* 
Sale Ribbon
*/

  .wdp-ribbon{
	display: inline-block;
    padding: 2px 15px;
	position: absolute;
    right: 0px;
    top: 20px;
    line-height: 24px;
	height:28px;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
	border-radius: 0;
    text-shadow: none;
    font-weight: normal;
    background-color: #ff7010 !important;
    z-index: 3;
    color: #ffffff;
    font-weight: bolder;
}

.wdp-ribbon-two:before, .wdp-ribbon-two:before {
    display: inline-block;
    content: "";
    position: absolute;
    left: -14px;
    top: 0;
    border: 9px solid transparent;
    border-width: 14px 8px;
    border-right-color: #ff7010 ;
}
.wdp-ribbon-two:before {
    border-color: #ff7010 ;
    border-left-color: transparent!important;
    left: -9px;
}

.prev-price{
    font-weight: lighter;
    font-style: italic;
    text-decoration: line-through;
    color: dimgray;
    font-size: 0.85rem;
}

.ex-gst{
    font-weight: lighter;
    color: rgb(248, 17, 17);
    font-size: 0.75rem;
}