@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&family=Quicksand&display=swap');

.partner-icon{
    font-size: 7rem;
    margin-top: 0.3rem;
    margin-left: 0.8rem;
    padding: 10px; 
    transform: scale(1);
    transition: all 1s ease !important;
}

.partner-icon:hover{
    transform: scale(1.2);
}

.partner-icon-container{
    overflow: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 8rem;
    height: 6.9rem;
    border-style: none;
    align-content: center;
    
}

.Facebook-icon{
    color: rgb(42, 111, 238)
}

.Linkedin-icon{
    color: rgb(53, 171, 240)
}

.Google-icon{
    color: rgb(230, 52, 52)
}

.google-logo{
    width: 8rem;
    height: 6.9rem;
}

.partner-icon-label-container{
    font-family: 'Open Sans Condensed', sans-serif;
    text-align: center;
    font-size: 1.3rem;
    padding-top: 0;
    margin-top: 0;
}